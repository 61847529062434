import React from 'react';  

export default function Footer() {
  return (     
    <div>    
      <footer style={{
        "text-align": "center",
        "padding-top": "0.25rem",
        "padding-bottom": "0.25rem"
      }}>
        <p>Made by Filipos with ❤️ | © 2022</p>
      </footer>
    </div>   
  ); 
};